.image-stage {
    position: relative;
    @include media-breakpoint-down(xl) {
       pointer-events: none!important;
    }
}

.image-stage .image-stage-doc {
    z-index: 100;
    margin-bottom: 2rem;
}
.image-stage .image-stage-bbox {
    position: absolute;
    /*border: 1px dashed rgba(0, 123, 251, 0.6);*/
    z-index: 105;
}
.image-stage .image-stage-databox {
    position: absolute;
    z-index: 105;
    #border: 2px solid rgba(0, 123, 251, .8);
    box-shadow: inset 0px 0px 0px 2px rgba(0, 123, 251, .6);
    border-radius: 5px;
    padding: 4px;
}
.image-stage .image-stage-databox.active {
    box-shadow: inset 0px 0px 0px 2px rgba(0, 123, 251, 1);
    cursor: pointer;
}
.image-stage .image-stage-databox.active .image-stage-databox-key {
    display: block;
}
.image-stage .image-stage-databox.copied {
    box-shadow: inset 0px 0px 0px 2px #E35D79;
}
.image-stage .image-stage-databox.copied .image-stage-databox-key {
    background-color: #E35D79;
}
.image-stage .image-stage-databox-key {
    position: absolute;
    background-color: rgba(0, 123, 251, 1);
    color: white;
    border-radius: 5px;
    padding: 2px 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    max-width: 100%;
    font-size: 0.9rem;
    display: none;
}

.image-stage-databox.top.active {
    border-radius: 0px 0px 5px 5px;
}
.image-stage-databox.bottom.active {
    border-radius: 5px 5px 0px 0px;
}
.image-stage-databox.left.active {
    border-radius: 0px 5px 5px 0px;
}
.image-stage-databox.right.active {
    border-radius: 5px 0px 0px 5px;
}
.image-stage-databox.top .image-stage-databox-key {
    border-radius: 5px 5px 0px 0px;
    bottom: 100%;
    margin: 0px -4px 0px -4px;
}
.image-stage-databox.bottom .image-stage-databox-key {
    border-radius: 0px 0px 5px 5px;
    top: 100%;
    margin: 0px -4px 0px -4px;
}
.image-stage-databox.left .image-stage-databox-key {
    border-radius: 5px 0px 0px 5px;
    right: 100%;
    height: 100%;
    vertical-align: text-bottom;
    font-size: calc(75%);
}
.image-stage-databox.right .image-stage-databox-key {
    border-radius: 0px 5px 5px 0px;
    left: 100%;
    height: 100%;
    vertical-align: text-bottom;
    font-size: calc(75%);
}

.image-stage .image-stage-databox-0 {
    #border: 2px solid rgba(215, 51, 74, 0.9);
    box-shadow: inset 0px 0px 0px 2px rgba(215, 51, 74, 0.9);
}
.image-stage .image-stage-bbox:hover {
    /*background-color: rgba(0, 123, 251, 0.3);*/
    border: 2px dotted rgba(0, 123, 251, 0.8);
    cursor: pointer;
}
.image-stage .image-stage-activebox {
    z-index: 120;
    background-color: rgba(0, 123, 251, 0.3);
    border: 2px solid rgba(0, 123, 251, 0.8);
    cursor: pointer;
    .resize-handle {
        border: 4px solid rgba(0, 123, 251, 0.8);
        transform: scale(.4);
        background: $white;
    }
}

.image-stage .status-flag {
    background-color: #6E757C;
    color: white;
    transform: rotate(45deg);
    z-index: 4;
    position: fixed;
    top: 200px;
    right: -50px;
    text-align: center;
    width: 200px;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: bold;
}
.image-stage .status-flag.inprogress {
    background-color: #357CF7;
}
.image-stage .status-flag.confirmed {
    background-color: #55A451;
}
.data-fields-box {
    position: relative;
}
.data-field, .data-field-1, .data-field-0 {
    position: absolute;
    padding: 0.3rem 1rem;
    background-color: rgba(0, 123, 251, 0.9);
    color: white;
    border-radius: 5px;
}
.data-field-0 {
    background-color: rgba(215, 51, 74, 0.9);
}
.document-preview {
    position: fixed;
    height: 100%;
    width: 40%;
    right: 0;
    top: 0;
    background-color: rgba(0,0,0,0.4);
    border-left: 1px solid rgba(0,0,0,0.2);
    text-align: center;
    backdrop-filter: blur(4px);
    z-index: 1040;
}
.document-preview img {
    //box-shadow: 0px 10px 15px 0 rgba(0,0,0,0.3);
}
.document-preview h5 {
}

.badge-document-status {
    &.inprogress {
        background-color: $info;
    }
    &.confirmed {
        background-color: $success;
    }
    &.waiting {
        background-color: $danger;
    }
}

#document-preview-heading-box {
    background-color: $body-bg;
    position: relative;
    z-index: 5;
}

#document-sidebar-tools {
    top: 4.5rem;
    z-index: 4;
}

#document-actions {
    width: 3rem;
    .nav-item {
        text-align: center;
    }
    .btn {
        > [class^=ai-], svg {
            font-size: 1.3rem;
        }
    }

    @include media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: 1rem;
        .nav-item {
            display: inline-block;
        }
        .dropdown-divider {
            display: none;
        }
    }
}
@include media-breakpoint-down(lg) {
    #discussion-offcanvas {
        position: fixed;
        top: 0;
    }
    #discussion-thumbnail {
        padding: 0 !important;
        margin: 1rem auto !important;
    }
}