.react-bootstrap-table {
    table {
        font-size: 0.9em;
        transition: all 1s;
    }
}

table {
    &.table-condensed > tbody > tr > td,
    &.table-condensed > tbody > tr > th,
    &.table-condensed > tfoot > tr > td,
    &.table-condensed > tfoot > tr > th,
    &.table-condensed > thead > tr > td,
    &.table-condensed > thead > tr > th {
        padding: .35rem .75rem;
    }
    &.table-bordered {}
    thead {
        th {
            white-space: nowrap;
            vertical-align: middle;
            font-weight: $font-weight-medium;

            .caret {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
                border-top: 4px dashed;
                border-top: 4px solid \9;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
            }
            .dropdown, .dropup {
                position: relative;
            }
            .dropup {
                .caret {
                    content: "";
                    border-top: 0;
                    border-bottom: 4px dashed;
                    border-bottom: 4px solid\9;
                }
            }

            .order {
                > .dropdown > .caret {
                    margin: 10px 0 10px 5px;
                    color: $gray-500;
                }
                > .dropup > .caret {
                    margin: 10px 0;
                    color: $gray-500;
                }
            }
            .react-bootstrap-table-sort-order > .caret {
                margin: 10px 6.5px;
                color: $primary;
            }
            &.sortable {
                cursor: pointer;
                vertical-align: bottom;
            }
        }
    }
    tbody {
        tr {
            &:not(.non-selectable-row) {
                &.selection-row, &:hover {
                    transition: all 275ms;
                    background-color: map-get($theme-faded-colors, "primary");
                }
                &:hover {
                    td.selection-cell {
                        .form-check-input:not(:checked) {
                            background-color: $white;
                        }
                    }
                }
            }
            td, th {
                vertical-align: middle;
            }
            &.in-tempbox {
                transition: all 275ms;
                background-color: map-get($theme-faded-colors, "warning");
                .preview-link {
                    color: $warning;
                }
            }
        }
    }

     .selection-cell, .selection-cell-header {
        .form-check {
            margin-left: .55rem;
            margin-top: .35rem;
            .form-check-input {

            }
        }
    }

    :not(:first-child) {
        border-top: none!important;
    }
}

table .bar-codes {
    svg {
        font-size: 1.25rem;
    }
}

table .btn-icon {
    padding: 0.3625rem 0.4875rem;
    svg {
        max-width: inherit;
        font-size: 18px;
    }
}