/* CSS declarations go here */
*, :after, :before {
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    height: 100%;
}
body {
    overflow-x: hidden;
    &.no-scroll {
        overflow: hidden;
    }
}


@include media-breakpoint-up(xl) {
    .wrapper {
        align-items: stretch;
        display: flex;
        width: 100%;
        > section {
            flex: 0 0 100%;
        }
    }
}

section.main-wrapper #main {
    &.logged-in {
        @include media-breakpoint-between(xs, md) {
            padding-top: 70px;
        }
    }
}

h1 .icon {
    height: 30px;
    margin-right: 5px;
}
th.sortable {
    cursor: pointer;
}
.nav-item {
    cursor: pointer;
}
.nav-item .btn svg {
    margin-top: -2px;
    font-size: 1.3em;
}

.table-ntb thead th {
    border-top: none;
    color: #999;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 500;
}
.table-ntb th, .table-ntb td {
    font-size: 0.9em;
}
.dropdown-menu.show {
    z-index: 2000 !important;
}
.dropdown-menu.show button:hover {
    background-color: $primary;
    color: white;
}
.dropdown-right {
    z-index: 2000 !important;
}
.navbar.fixed-top {
    z-index: 1021;
}
.navbar-brand {
    font-family: 'Ubuntu';
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.3em;
    letter-spacing: -1px;
}
.navbar-brand img {
    vertical-align: top;    
}
.container-fluid {
}


video#preview {
    border: 10px solid #fff;
    border-radius: 10px;
}

section.dnd {
}

section.dnd-active {
    background-color: #538051;
}
.header-buttons button {
    margin-left: 5px;
}

.react-pdf__Document .annotationLayer {
    display: none;
}
.react-pdf__Document .react-pdf__message {
    padding-top: 190px;
    text-align: center;
}


.file-drop {
    display: inline-block;
}
.file-drop > .file-drop-target.file-drop-dragging-over-frame .btn {
    border-style: dashed;
    content: "";
}

.badge-box .badge:not(:last-child) {
    margin-right: 5px;
}


.auth-box {
    width: 25rem;
}

.btn.btn-outline-light {
    border-color: #bbb;
    color: #888;
}
.btn.btn-light {
    background-color: #aaa;
    border-color: #aaa;
    color: #fff;
}
.btn.btn-light:hover {
    background-color: #999;
    border-color: #999;
}
.react-datepicker__month-container, .popover {
    box-shadow: 0px 10px 15px 0 rgba(0,0,0,0.3);
}
.react-datepicker__input-container .btn.btn-primary svg {
    visibility: hidden;
}
.btn.btn-datepicker {
    margin-right: 2px;
}
.react-datepicker__close-icon {
    right: 6px;
}
.react-datepicker__close-icon::after {
    background-color: #fff;
    color: $primary;
}
.dropdown-menu-reset {
    background-color: #eee;
}
.search-clear {
    position: absolute;
    right: 43px;
    top: 2px;
    z-index: 10;
}
.table-actions {
    margin-right: -5px;
    margin-left: -7px;
}
.table-actions svg {
    font-size: 1.25em;
    margin-left: 5px;
}
.tempbox {
    position: relative;
}
.popover {
    z-index: 1020;
}
.tempbox .popover .popover-body {
    padding: 0px !important;
}
.badge-upper-right {
    position: absolute !important;
    top: -7px !important;
    right: -7px;
}
.tempbox-popover {
    max-width: none;

}
.preview-search {
    padding: 10px 0px 20px 0px;
    position: relative;
}
.preview-search-spinner {
    position: absolute;
    right: 11px;
    top: 11px;
}
.qr-code-box {
    top: calc(100vh - 215px);
    right: -40px;
    position: absolute;
    width: 150px;
    z-index: 1000;
}

.card-header {
    border-bottom: none;
}

.form-check-input:hover {
    cursor: pointer;
}

h4 {
    svg {
        vertical-align: top;
        font-size: 1.75rem;
    }
}

.shadow, .popover, .dropdown-menu.show {
    box-shadow: $box-shadow-lg !important;
}

.btn {
    &:disabled {
        opacity: .5;
        color: $gray-600!important;
        pointer-events: none;
    }
}

.avatar span > span {
    font-weight: $font-weight-lighter;
}

div[id*="white-tooltip"] {
    .tooltip-inner {
        background-color: $white;
        color: $gray-900;
        box-shadow: $box-shadow-lg!important;
        padding: .5rem .7rem;
    }
    .tooltip-arrow:before {
        border-left-color: $white !important;
        border-right-color: $white !important;
    }
}

.btn-tooltip-wrapper  {
    position: relative;
    &:disabled .btn-tooltip, &.disabled .btn-tooltip {
        opacity: 0!important;
    }
    &:hover .btn-tooltip {
        opacity: 1;
        z-index: 9999;
        display: inline-block;
    }
}

.btn-tooltip {
    pointer-events: none;
    display: none;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    transition: opacity 0.2s ease-in-out;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    white-space: nowrap;
    opacity: 0;
    background-color: $white!important;
    color: $gray-900!important;
    box-shadow: $box-shadow-lg!important;
    padding: .5rem .7rem !important;
}


a.disabled{
    pointer-events: none;
    cursor: default;
}

.sortableHelper {
   z-index: 10000 !important;
}


.bg-admin {
    background-color: $color-admin;
}

.bg-editor {
    background-color: $color-editor;
}

.bg-viewer {
    background-color: $color-viewer;
}

.color-admin {
    color: $color-admin;
}

.color-editor {
    color: $color-editor;
}

.color-viewer {
    color: $color-viewer;
}

a.disabled {
    color: $gray-500;
    cursor: not-allowed;
    opacity: .7;
}

.border-white {
    border-color: $white !important;
}

.opacity-0 {
    opacity: 0!important;
}

.opacity-1 {
    opacity: 1!important;
}

.image-orientation-from-image {
    image-orientation: from-image !important;
}
.offcanvas-body h6 {
    padding-top: 8px;
}