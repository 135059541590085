
.react-page-file-drop {}
.react-page-file-drop > .file-drop-outer {}

.react-page-file-drop > .file-drop-dragging-over-frame {}

.file-drop {
    transition: opacity 175ms;
    .target {
        border-radius: 10px;
        border: 3px solid transparent;
        &.file-drop-dragging-over-frame {
            opacity: .7;
            background-color: $white;
            border: 3px dashed $primary;
        }

        &.file-drop-dragging-over-target {
            border-style: solid;
            opacity: 1;
        }
    }
}