//
// Reboot
// --------------------------------------------------

// Import Google Fonts (Rubik)
@import url($path-to-fonts);

html * {
  @include font-smoothing;
}

// Sticky footer
html, body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.page-wrapper {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

main#main.logged-in {
  margin-top: 5rem;
  @include media-breakpoint-up(lg) {
    padding-right: 0px;
  }
}

// Links
a:focus { outline: none; }
a { transition: $link-transition; }

// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg { max-width: 100%; }

// Responsive iframes
iframe {
  width: 100%;
}

// Full height iframe
.iframe-full-height-wrap {
  min-height: 18rem;
  overflow: hidden;
}
.iframe-full-height {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Text Selection Color
::selection {
  background: $user-selection-color;
}
::-moz-selection {
  background: $user-selection-color;
}

// Change Bootstrap default .container-fluid paddings
@include media-breakpoint-up(xl) {
  .container-fluid {
    //margin: 0;
    //margin-left: -5px;
    /*
    margin-left: $grid-gutter-width * -1;
    margin-right: 0;
    padding-right: 0;
    padding-left: $grid-gutter-width;
    */
  }
}
@include media-breakpoint-up(lg) {
  .container-fluid {
    //margin-left: -5px;
    //margin: 0;
  }
}

small { font-weight: inherit; }

// Remove top padding from body when screen size below md (992px)
@include media-breakpoint-down(md) {
  body { padding-top: 0 !important; }
}

// Disable button :focus outline
button:focus {
  outline: none;
}

// Horizontal rule - light version
.hr-light {
  color: $border-light-color;
}

.table {
  margin-bottom: 0;
}
.react-bootstrap-table-padded {
  background-color: white;
  padding: 20px;
  margin-bottom: 1rem;
  border-radius: 12px;

  .table thead tr th {
    border-top-color: #fff;
  }
  .table tbody tr:last-child th, .table tbody tr:last-child td {
    border-bottom-color: #fff;
  }
}
.table-rounded {
  margin-bottom: 1rem;
  border-radius: 12px;

  .table {
    margin-bottom: 0;
  }
  .table-responsive {

  }
}

.ReactCrop {
  border: 1px solid #dddddd;
  user-select: none;
}
.image-stage {
  user-select: none;
}