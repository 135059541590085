nav#navigation-bar {
  background-color: $body-bg;
  margin-bottom: 0;
  border-bottom: 1px solid transparent;

  @include media-breakpoint-up(md) {
    &:not(.navbar-floating) {
      box-shadow: $box-shadow-lg;
      border-color: #E4EBF6;
      background-color: $white;
    }
  }
  @include media-breakpoint-down(md) {
    &.navbar-stuck {
      animation: none!important;
    }
  }

  .navbar-tool-icon-box-img {
    width: 100%;
  }
  .dropdown-menu {
    top: 2.25rem;
  }
  .navbar-tool {
    cursor: pointer;
    pointer-events: all;
    &.active {
      cursor: pointer;
    }
    > .navbar-tool-icon-box {
      width: 2.15rem;
      height: 2.15rem;
    }
  }
  .dropdown {
    padding: 0 .75rem;
  }
  .navbar-tool-icon-box-img {
    border: none;
  }
  .search-icon {
    font-size: 1.7rem;
  }

  .quick-search-form {
    z-index: 2001 !important;
    padding: 0;
    max-width: none;
    margin-left: -5px;

    .dropdown {
      padding: 0;
    }
    .dropdown-menu {
      width: calc(100%) !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .show.dropdown .input-group > * {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .dropdown h6 {
      font-size: 14px;
    }
    .dropdown-item {
      &:hover {
        transition: all 275ms;
        background-color: map-get($theme-faded-colors, "primary");
      }
    }
    .input-group {
      z-index: 2001;
    }
    .form-control {
      width: 70%;
      box-shadow: none;
    }

    .form-control, .input-group-text {
      background-color: $body-bg;
    }
  }

  @include media-breakpoint-up(sm) {
    &:not(.navbar-floating) {
      .form-control, .input-group-text {
        background-color: $white;
      }
    }
  }

  @include media-breakpoint-between(xs, md) {
    background-color: #e7ebfa;
    box-sizing: border-box;
    border: none;

    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 500;
  }

  @include media-breakpoint-down(sm) {
    #navbar-logo {
      padding: 0;

      img {
        min-width: 140px;
      }
      overflow: hidden;
      width: 50px;
    }
  }
}
