#hamburger-menu {
    top: 60px;

    h6 {
      color: $gray-500;
      font-size: 14px;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      margin-bottom: .4rem;
    }

  .nav-item {
    border: none;
  }

  .nav-item {
    border-top: none;
    display: inline-block;
    > .nav-link {
      width: 100%;
      padding: 0.175rem 0.425rem;
      font-size: 16px;
      border-left: 4px solid transparent;
      border-top-right-radius: 0.75rem !important;
      border-bottom-right-radius: 0.75rem !important;
        .navbar-icon-box {
          position: relative;
        }
      &.active {
          border-left-color: $nav-link-active-color;
          background-color: map-get($theme-faded-colors, "primary");
      }
      &.disabled {
        cursor: default;
        opacity: .5
      }
      .link-container {
        padding: .25rem .9rem;
        border-radius: $border-radius;
      }
      svg {
        font-size: 22px;
      }
    }
    .navbar-tool {
        display: inline-block;
        .navbar-tool-badge, .popover-icon-wrapper > .navbar-tool-badge {
          top: -0.5rem;
          right: -0.7rem;
        }
    }
  }

    ul.navbar-nav {
      padding: .45rem;
      width: 100%;
      overflow: hidden;
      .nav-link {
        white-space: nowrap;
        align-items: center !important;
        .navbar-icon-box {
          margin-right: .9rem !important;
        }
        > .link-container {
          &:hover {
            transition: all 275ms;
            background-color: map-get($theme-faded-colors, "primary");
          }
        }
      }
    }



  @include media-breakpoint-between(sm, lg) {
    h6 {
      margin-top: 2rem;
    }

     ul.navbar-nav {
      padding-top: 0;
      padding-bottom: 1.5rem;
    }
  }

  @include media-breakpoint-down(sm) {
     ul.navbar-nav {
      padding-bottom: 3.5rem;
    }
  }
}