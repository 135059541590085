#table-search {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    .input-group-text {
        padding: 1.1625rem 0.825rem;
        &:first-child {
            border-bottom-left-radius: 0;
        }
        &:not(:first-child) {
            border-left-width: 0;
        }
        &:last-child {
            border-bottom-right-radius: 0;
        }
    }
    .input-group-text, .form-control {
        border-bottom-width: 0;
        background-color: $body-bg;
        border-color: #fff;
        z-index: 2;
    }
    .form-control {
        padding: 1.1625rem 0.125rem;
        border-left-width: 0;
        border-right-width: 0;
        &:focus {
            border-color: #fff;
            box-shadow: none;
        }
    }
}
.react-bootstrap-table {
    border-color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .table thead th {
        border-top: 1px solid #E9E9F1;
        border-bottom: 1px solid #E9E9F1 !important;
    }
}
.react-bootstrap-table-pagination {
    margin-top: 1rem;
}